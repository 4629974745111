<template>
  <v-row justify="center">
    <v-col cols="9">
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Exportar</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="transparent" dense>
            <v-list-item v-for="d in choices" :key="d.title" style="border-bottom: 1px solid #D8E0EA" :to="{name: d.view, params: $route.params}" :ripple="false">
              <v-list-item-content>
                <v-list-item-title class="body-2 text--secondary font-weight-medium">{{d.title}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn :to="{name: d.view, params: $route.params}" text color="primary">Ir a exportar<v-icon color="primary" small right>mdi-arrow-right</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    choices: [
      {
        view: 'AccountCustomerExport',
        title: 'Clientes'
      },
      {
        view: 'AccountExportDocuments',
        title: 'Facturas'
      },
      {
        view: 'AccountExportCreditNote',
        title: 'Notas de crédito'
      }
    ]
  })
}
</script>